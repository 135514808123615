<footer
  class="d-flex flex-wrap justify-content-between align-items-center py-3 px-5 mt-4 border-top page-content fixed-bottom"
>
  <ul class="nav col-md-4 list-unstyled d-flex">
    <li class="ms-3">
      <a class="text-body-secondary" routerLink="/imprint">Impressum</a>
    </li>
    <li class="ms-3">
      <a class="text-body-secondary" routerLink="/data-privacy">Datenschutz</a>
    </li>
  </ul>
  <div class="col-md-4 d-flex justify-content-end pe-3">
    <span class="d-block lh-sm small text-body-secondary text-end"
      >&copy; Ernst Klett Sprachen GmbH {{ today | amDateFormat : 'YYYY' }}.
      Alle Rechte vorbehalten.
    </span>
  </div>
</footer>
