<!--////////////////// PreLoader Start//////////////////////-->
<!--
<div class="loader bg-gradient-primary text-white">
  <div
    class="content flex-column p-4 pb-0 d-flex justify-content-center align-items-center flex-column-fluid position-relative">
    <div class="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spinner-grow  me-2"><line x1="12" y1="2" x2="12" y2="6"/><line x1="12" y1="18" x2="12" y2="22"/><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"/><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"/><line x1="2" y1="12" x2="6" y2="12"/><line x1="18" y1="12" x2="22" y2="12"/><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"/><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"/></svg>
      <div>
        <span>Loading...</span>
      </div>
    </div>
  </div>
</div>
-->
<!--////////////////// /.PreLoader END//////////////////////-->

<div class="d-flex flex-column flex-root">
  <!--Page-->
  <div class="page d-flex flex-row flex-column-fluid">
    <!--///////////Page sidebar begin///////////////-->
    <aside class="page-sidebar">
      <div class="h-100 flex-column d-flex justify-content-start">
        <!--Sidebar-Menu-->
        <div class="aside-menu px-3 my-auto" data-simplebar>
          <nav
            class="flex-grow-1 h-100 d-flex align-items-center"
            id="page-navbar"
          >
            <!--:Sidebar nav-->
            <ul class="nav flex-column collapse-group collapse d-flex">
              <li class="nav-item">
                <a
                  routerLink="/entitlements/dashboard"
                  routerLinkActive="active"
                  class="nav-link d-flex align-items-center justify-content-center text-truncate"
                  matTooltip="Dashboard"
                  matTooltipPosition="after"
                >
                  <i class="fa-sharp fa-light fa-chart-network"></i>
                  <span class="sidebar-text">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  routerLink="/entitlements/my"
                  routerLinkActive="active"
                  class="nav-link d-flex align-items-center justify-content-center text-truncate"
                  matTooltip="Meine Lizenzen"
                  matTooltipPosition="after"
                >
                  <i class="fa-light fa-file-certificate"></i>
                  <!--Sidebar nav text-->
                  <span class="sidebar-text">Meine Lizenzen</span>
                </a>
              </li>
              <ng-container *ngIf="isAdmin">
                <li
                  class="nav-item mt-2 sidebar-title text-truncate small opacity-50"
                >
                  <i class="fa-solid fa-ellipsis"></i>
                  <span>Admin</span>
                </li>
                <li class="nav-item">
                  <a
                    routerLink="/entitlements/admin"
                    routerLinkActive="active"
                    class="nav-link d-flex align-items-center justify-content-center text-truncate"
                    matTooltip="Lizenzen"
                    matTooltipPosition="after"
                  >
                    <i class="fa-light fa-file-certificate"></i>
                    <!--Sidebar nav text-->
                    <span class="sidebar-text">Lizenzen</span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
    <!--///////////Page Sidebar End///////////////-->

    <!--///Sidebar close button for 991px or below devices///-->
    <div class="sidebar-close d-lg-none">
      <a (click)="toggleSidebar()"></a>
    </div>
    <!--///.Sidebar close end///-->

    <!--///////////Page content wrapper///////////////-->
    <main class="page-content d-flex flex-column flex-row-fluid">
      <!--//page-header//-->
      <header
        class="navbar mb-3 px-3 px-lg-6 px-3 px-lg-6 align-items-center page-header navbar-expand navbar-light"
      >
        <a href="/" class="navbar-brand d-block">
          <div class="d-flex align-items-center flex-no-wrap text-truncate">
            <!--Sidebar-icon-->
            <img
              src="https://sso-staging.dialogcrm.cloud/auth/resources/5l3rh/login/eks/img/Klett-Logo_screen_line_800px.jpg"
            />
            <h1>Lizenzen</h1>
          </div>
        </a>

        <ul class="navbar-nav ms-auto d-flex align-items-center h-100">
          <li *ngIf="getUserName() !== ''">
            <a
              class="nav-link height-40 px-2 d-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center" [matMenuTriggerFor]="menu">
                <!--Avatar with status-->
                <i class="fa-light fa-circle-user pe-1"></i>
                <span class="d-none d-md-inline-block">{{
                  getUserName()
                }}</span>
                <i class="fa-thin fa-chevron-down ps-1"></i>
              </div>
            </a>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <i class="fa-light fa-arrow-right-from-bracket"></i>
                <a (click)="logout()"><span>Logout</span></a>
              </button>
            </mat-menu>
          </li>
          <li
            class="nav-item dropdown ms-3 d-flex d-lg-none align-items-center justify-content-center flex-column h-100"
          >
            <a
              (click)="toggleSidebar()"
              class="nav-link sidebar-trigger-lg-down size-40 p-0 d-flex align-items-center justify-content-center"
            >
              <i class="fa-light fa-bars"></i>
            </a>
          </li>
        </ul>
      </header>
      <!--Main Header End-->

      <!--//Page content//-->
      <div
        class="content px-3 px-lg-6 pt-3 d-flex flex-column-fluid position-relative"
      >
        <div class="container-fluid px-0">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!--//Page content End//-->

      <!--/.Page Footer End-->
    </main>
    <!--///////////Page content wrapper End///////////////-->
  </div>
</div>
<!--//Page-footer//-->
<ui-footer></ui-footer>
